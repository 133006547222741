<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol xl="6">
        <div class="w-100">
        <div class="clearfix">
          <h1 class="display-3">Ошибка 404</h1>
          <h4 class="pt-3">Страница не найдена. Проверьте, правильно ли вы ввели адрес или перейдите по одной из ссылок ниже.
          </h4>
        </div>
      </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
